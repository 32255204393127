@import '~antd/dist/antd.less';

/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.anticon svg {
  /* display: inline-block; */
  width: 1em;
  /* height: auto; */
  min-width: auto;
  /* min-height: 1rem; */
}

.bmf-theme-color{
  color: #54c246;
}
.bmf-theme-bgcolor{
  background-color: #54c246;
}
.bmf-theme-bgcolor-light{
  background-color: #c7e9c3;
}
.bmf-theme-bdcolor{
  border-color: #54c246;
}

.bmf-theme-color-sc{
  color: #7946c2 !important;
}
.bmf-theme-bgcolor-sc{
  background-color: #7946c2;
}
.bmf-theme-bdcolor-sc{
  border-color: #7946c2;
}

.bmf-theme-color-th{
  color: #092b66;
}
.bmf-theme-bgcolor-th{
  background-color: #092b66;
}
.bmf-theme-bgcolor-th-light{
  background-color: #DEEBF7;
}
.bmf-theme-bdcolor-th{
  border-color: #092b66;
}

.bmf-theme-filter-brightness-1{
  filter: brightness(1.1);
}

@keyframes Main-title-appear {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.bmf-theme-title-main{
  color: #fff;
  font-size: 80px;
  font-weight: bold;
  animation: 0.8s Main-title-appear;
}

a:hover{
  text-decoration: underline;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@primary-color: #7946c2;@layout-header-background: #823d78;@text-color-secondary-dark: #fff;@font-size-base: 16px;@text-color: #000;@background-color-light: #faf2fa;