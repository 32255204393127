@keyframes show-sub-menu{
    0% {max-height: 0px;}
    100% {max-height: 1000px;}
}

.headernav-wrap{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: visible;
    top: 0;
    z-index: 999;
    width: 100%;
    padding-left: 50px;
    padding-right: 40px;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.3);
    transition: background-color 0.5s;
    .headernav-logo{
        display: inline-block;
        vertical-align: middle;
        color: #fff;
        margin-right: auto;
        font-size: 50px;
        line-height: 1;
        font-family: Book Antiqua;
        font-style: italic;
        font-weight: bold;
    } 
    .headernav-item-wrap{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        overflow: visible;
        height: 64px;
        width: 12%;
        max-width: 140px;
        min-width: 100px;
        text-align: center;
        vertical-align: middle;
        padding-right: 20px;
        padding-left: 20px;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        transition: filter 0.5s;
        transition: background-color 1s;
        .headernav-item-sub-icon{
            display: none;
            position: relative;
        }
        .headernav-item-sub-menu{
            display: none;
            position: relative;
        }
        .headernav-item-sub-menu{
            position: absolute;
            overflow: hidden;
            width: 250px;
            top: 50px;
            left: 0px;
            margin-top: 15px;
            animation: show-sub-menu 2s;
            box-shadow: 2px 2px 4px rgba($color: #000000, $alpha: 0.3);
            .headernav-item-sub-menu-item{
                font-size: 16px;
                text-align: left;
                padding-top: 15px;
                padding-bottom: 15px;
                padding-left: 15px;
                background-color: #fff;
                transition: filter 0.5s;
            }
            .headernav-item-sub-menu-item:hover{
                filter: brightness(0.8);
            }
        }
    }
    .headernav-item-wrap:hover{
        .headernav-item-sub-icon{
            display: block;
        }
        .headernav-item-sub-menu{
            display: block;
        }
    }
    .headernav-item-wrap.activated{
        border-bottom-width: 4px;
        border-bottom-style: solid;
    }
    .headernav-item-wrap:hover:not(.has-sub){
        filter: brightness(0.8);
    }
}